<template>
  <tr>
    <td>{{ index + 1 }}</td>
    <td>{{ rowData.accountName }}</td>
    <td>{{ rowData.taxName }}</td>
    <td>{{ rowData.deptName }}</td>
    <td>{{ rowData.serviceCode }}</td>
    <td>{{ rowData.partner }}</td>
    <td>{{ rowData.debit | currency }}</td>
    <td>{{ rowData.credit | currency }}</td>
  </tr>
</template>

<script>
export default {
  name: "PopStatementVoucherListLine",
  props: {
    rowData: Object,
    index: Number,
  },
};
</script>

<template>
  <div class="blank_asset3">
    <div class="head_tbl">
      <div class="pos_left">
        <strong class="tit_text">{{ certificateDesc }}</strong>
      </div>
      <div class="pos_right">
        <span class="txt_info_888">통화코드 : {{ rowData.currency }}</span>
      </div>
    </div>
    <div class="tbl_comm tbl_view">
      <table>
        <colgroup>
          <col style="width: 171px" />
          <col style="width: 200px" />
          <col style="width: 170px" />
          <col style="width: 200px" />
          <col style="width: 170px" />
          <col />
        </colgroup>
        <tbody>
          <tr>
            <th>전표일자</th>
            <td>{{ rowData.voucherDate }}</td>
            <th>증빙일자</th>
            <td>{{ rowData.proofDate }}</td>
            <th>저장상태로 전표 생성</th>
            <td>
              <div class="box_choice">
                <div class="item_choice">
                  <!-- disabled 상태시 disabled 클래스 추가 -->
                  <input
                    :id="'voucherSaveModeYn' + index"
                    v-model="rowData.voucherSaveModeYn"
                    type="checkbox"
                    class="inp_choice"
                    name="voucherSaveModeYn"
                    :value="true"
                  />
                  <label class="lab_choice" :for="'voucherSaveModeYn' + index">
                    <span class="ico_account ico_check" />저장상태로 전표 생성
                  </label>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="tbl_comm tbl_list tbl_list_sm12">
      <table>
        <colgroup>
          <col style="width: 52px" />
          <!-- No. -->
          <col />
          <!-- 계정과목 -->
          <col style="width: 144px" />
          <!-- 세목 -->
          <col style="width: 144px" />
          <!-- 부서 -->
          <col style="width: 144px" />
          <!-- 프로젝트코드 -->
          <col style="width: 144px" />
          <!-- 거래처 -->
          <col style="width: 141px" />
          <!-- 차변 -->
          <col style="width: 141px" />
          <!-- 대변 -->
        </colgroup>
        <thead>
          <tr>
            <th>No.</th>
            <th>계정과목</th>
            <th>세목</th>
            <th>부서</th>
            <th>프로젝트코드</th>
            <th>거래처</th>
            <th>차변</th>
            <th>대변</th>
          </tr>
        </thead>
        <tbody>
          <PopStatementVoucherListLine
            v-for="(item, index) in voucherItems"
            :key="index"
            :index="index"
            :rowData="item"
            @onClickPopAccount="$emit('onClickPopAccount')"
            @onClickPopTax="$emit('onClickPopTax')"
            @onClickPopDept="$emit('onClickPopDept')"
            @onClickPopService="$emit('onClickPopService')"
            @onClickPopPartner="$emit('onClickPopPartner')"
          />
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import PopStatementVoucherListLine from "./PopStatementVoucherListLine";
export default {
  name: "PopStatementVoucherItem",
  components: {
    PopStatementVoucherListLine,
  },
  props: {
    certificateDesc: String,
    rowData: Object,
    index: Number,
    proofType: String,
  },
  computed: {
    voucherItems() {
      if (this.proofType == "WT") {
        return this.rowData.voucherItems.filter((item) => item.surtaxYn === false);
      } else {
        return this.rowData.voucherItems;
      }
    },
  },
};
</script>
